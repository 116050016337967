import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { CarouselComponent } from './ui-components/carousel/carousel.component';
import { CarouselsComponent } from './components/carousel/carousel.component';
import { RouterModule } from '@angular/router';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { PartnerCarouselComponent } from './components/partner-carousel/partner-carousel.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavDropdownComponent } from './ui-components/nav-dropdown/nav-dropdown.component';
import { HeaderNavComponent } from './ui-components/header-nav/header-nav.component';
import { Routes } from './utils/router';
import { LightningComponent } from './components/lightning/lightning.component';
import { CookiesNoticeComponent } from './components/cookies-notice/cookies-notice.component';
import { CookieService } from 'ngx-cookie-service';
import { AboutHomeComponent } from './components/components/about-home.component';
import { IntroHeaderComponent } from './components/components/intro-header.component';
import { SolutionsContainerComponent } from './components/components/solutions-section.component';
import { TechnologySectionComponent } from './components/components/technology-section.component';
import { BrowserModule } from '@angular/platform-browser';
import { HomeSliderComponent } from './components/home-slider/home-slider.component';
import { SliderModule } from './components/sliders/slider.module';
import { WhyUsComponent } from './components/components/why-us.component';
import { ComponentFooterComponent } from './components/component-footer/component-footer.component';
import { IndustrycomponentsModule } from '../pages/industries/components/Industrtcomponent.module';
import { OurTechComponent } from './components/components/new-out-tect.component';
import { CarouselIconsComponent } from './components/components/techIcons.component';
@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    CarouselComponent,
    CarouselsComponent,
    ScrollToTopComponent,
    PartnerCarouselComponent,
    NavDropdownComponent,
    HeaderNavComponent,
    LightningComponent,
    CookiesNoticeComponent,
    IntroHeaderComponent,
    AboutHomeComponent,
    TechnologySectionComponent,
    SolutionsContainerComponent,
    HomeSliderComponent,
    WhyUsComponent,
    ComponentFooterComponent,
    OurTechComponent,
    CarouselIconsComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    CarouselModule ,
    BrowserAnimationsModule,
    SliderModule,
    IndustrycomponentsModule,
    NgOptimizedImage
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    CarouselsComponent,
    ScrollToTopComponent,
    PartnerCarouselComponent,
    HeaderNavComponent,
    LightningComponent,
    CarouselComponent,
    CookiesNoticeComponent,
    IntroHeaderComponent,
    AboutHomeComponent,
    TechnologySectionComponent,
    SolutionsContainerComponent,
    HomeSliderComponent,
    SliderModule,
    WhyUsComponent,
    ComponentFooterComponent,
    OurTechComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers:[Routes,CookieService]
})
export class SharedModule { }
