<div class="container-fluid bg-dark-1 text-light fadeInUp" data-wow-delay="0.1s">
    <div class="container">
        <div class="row gx-5">
            <div class="col-lg-4 col-md-6 footer-about">
                <div class="d-flex flex-column align-items-center justify-content-center text-center h-100  p-4">
                    <div><img src="assets/images/logos/logo_small_white.png" all="" style="margin-top: -70px;" class="img-responsive"></div>
                </div>
            </div>
            <div class="col-lg-8 col-md-6">
                <div class="row gx-5">
                    <div class="col-lg-4 col-md-12 pt-5 mb-5">
                        <div class="section-title section-title-sm position-relative pb-3 mb-4">
                            <h3 class="text-light mb-0">Get In Touch</h3>
                        </div>
                        <div class="d-flex mb-2">
                            <i class="bi bi-geo-alt text-primary me-2"></i>
                            <p class="mb-0">First Floor, Building 15, The Woodlands Office Park, 20 Woodlands Drive, Woodmead,2080</p>
                        </div>
                        <div class="d-flex mb-2">
                            <i class="bi bi-envelope-open text-primary me-2"></i>
                            <p class="mb-0"> sales@africaweather.com</p>
                        </div>
                        <div class="d-flex mb-2">
                            <i class="bi bi-telephone text-primary me-2"></i>
                            <p class="mb-0">+27 (0)11-300 7480</p>
                        </div>
                        <div class="d-flex mt-4">
                            <a class="btn btn-primary btn-square me-2" href="https://twitter.com/africaweather_"><i class="fab fa-twitter fw-normal"></i></a>
                            <a class="btn btn-primary btn-square me-2" href="https://www.facebook.com/AfricaWeather"><i class="fab fa-facebook-f fw-normal"></i></a>
                            <a class="btn btn-primary btn-square me-2" href="https://www.linkedin.com/uas/login?session_redirect=%2Fcompany%2F10460987%3Ftrk%3Dtyah%26trkInfo%3DclickedVertical%253Acompany%252CclickedEntityId%253A10460987%252Cidx%253A3-1-5%252CtarId%253A1474901914464%252Ctas%253Aafrica%2520weather"><i class="fab fa-linkedin-in fw-normal"></i></a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                        <div class="section-title section-title-sm position-relative pb-3 mb-4">
                            <h3 class="text-light mb-0">Industries</h3>
                        </div>
                        <div class="link-animated d-flex flex-column justify-content-start" *ngFor="let menu of industriesMenu">
                            <a class="text-light mb-2" [routerLink]="menu?.link"><i class="bi bi-arrow-right text-primary me-2"></i>{{menu?.name}}</a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                        <div class="section-title section-title-sm position-relative pb-3 mb-4">
                            <h3 class="text-light mb-0">Company</h3>
                        </div>
                        <div class="link-animated d-flex flex-column justify-content-start">
                            <a class="text-light mb-2" routerLink="/"><i class="bi bi-arrow-right text-primary me-2"></i>Home</a>
                            <a class="text-light mb-2" routerLink="/about"><i class="bi bi-arrow-right text-primary me-2"></i>About Us</a>
                            <a class="text-light mb-2" routerLink="/contact"><i class="bi bi-arrow-right text-primary me-2"></i>Contact Us</a>
                            <a class="text-light mb-2" routerLink="/legal/privacypolicy"><i class="bi bi-arrow-right text-primary me-2"></i>Privacy Policy</a>
                            <a class="text-light mb-2" [href]="businessTermsAndContionsUrl"><i class="bi bi-arrow-right text-primary me-2"></i>Business Terms and Conditions</a>
                            <a class="text-light" [href]="popiUrl"><i class="bi bi-arrow-right text-primary me-2"></i>POPI Contract Terms</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid text-white" style="background: #061429;">
    <div class="container text-center">
        <div class="row justify-content-end">
            <div class="col-lg-8 col-md-6">
                <div class="d-flex align-items-center justify-content-center" style="height: 75px;">
                    <p class="mb-0">&copy; <a class="text-white border-bottom" [href]="webUrl">{{webUrl}}</a> . All Rights Reserved.
                </div>
            </div>
        </div>
    </div>
</div>
