import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'industries-footer',
  template: `
    <footer class="cta-section component-background">
      <h5>{{ message }}</h5>
      <button routerLink="/contact" class="contact-btn component-background">Contact Us</button>
    </footer>
  `,
  styles: [
    `
      footer.cta-section {
        color: white;
        text-align: center;
        padding: 60px 10% 60px 10%; /*    top right bottom left */
      }
      footer h5 {
        color: white;
      }

      footer button {
        color: white;
        border: 1px solid white;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 5px;
        margin-top: 10px;
      }

      footer button:hover {
        background-color: #004d99;
      }
    `,
  ],
})
export class IndustriesFooterComponent {
  @Input('message') message: string | undefined;
}
