import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'technology-section',
  styleUrls: ['./technology-section.component.css'],
  template: `
    <div class="techsection component-background">
      <div *ngIf="isMobileOrTablet">
      <h1>Our Technology</h1>
        <icons-carousel/>
      </div>
      <div *ngIf="!isMobileOrTablet" class="tech-content">
      <img
  loading="lazy"
  [src]="image"
  alt="Our Technology"
  class="full-screen-image"
  (load)="onImageLoad()" (error)="onImageLoad()"
/>
      </div>
      <div class="section">
        <div class="content-section">
          <p>
            We deliver actionable insights that help businesses manage risk,
            optimise operations, and make smarter decisions. Leveraging advanced
            weather intelligence, we aggregate, model, and transform data into
            insights that empower over 15 industries. Powered by our Total
            Lightning Network and partnerships with SAWS and global providers,
            we provide the data businesses need to protect assets, streamline
            workflows, and seize opportunities.
          </p>
          <p>
            We deliver actionable insights that help businesses manage risk,
            optimise operations, and make smarter decisions. Leveraging advanced
            weather intelligence, we aggregate, model, and transform data into
            insights that empower over 15 industries. Powered by our Total
            Lightning Network and partnerships with SAWS and global providers,
            we provide the data businesses need to protect assets, streamline
            workflows, and seize opportunities.
          </p>
        </div>
      </div>
      <div class="content-button">
        <a routerLink="/contact" class="learn-more-btn">LEARN MORE</a>
      </div>
    </div>
  `,
})
export class TechnologySectionComponent implements OnInit {
  isMobileOrTablet: boolean | undefined;
  @Input('image') image:string | undefined;
  isLoading: boolean = false;

  constructor() {}

  ngOnInit() {
    this.isMobileOrTablet = window.innerWidth <= 768; // Adjust the width as needed for your breakpoints
  }
  onImageLoad() {
    this.isLoading = false;
  }
}
