import { Component, OnInit } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { environment } from 'src/environments/environment';
import { isDevMode } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  constructor(private config: AppConfig){}

 register_url : string =this.config.AFRICAWEATHER_REGISTER_URL;
 portal_url:string = this.config.AFRICAWEATHER_PORTAL_WEB;

 ngOnInit(): void {
}

}
